import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center"
  }
};
const _hoisted_2 = {
  key: 1,
  style: {
    "text-align": "center"
  }
};
import { ref, watch } from 'vue';
export default {
  __name: 'pageLoadState',
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    finished: {
      type: Boolean,
      default: () => false
    }
  },
  setup(__props) {
    const $props = __props;
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.loading ? (_openBlock(), _createBlock(_component_van_loading, {
        key: 0,
        size: "48rpx"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("加载中...")])),
        _: 1
      })) : _createCommentVNode("", true), !__props.loading && __props.finished ? (_openBlock(), _createElementBlock("text", _hoisted_2, "没有更多了")) : _createCommentVNode("", true)]);
    };
  }
};