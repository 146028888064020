import { HOME_CLASSIFY_TYPE_DIC } from '../dictionary/HOME_CLASSIFY_TYPE.js';

export const HOME_BAR_ITEM = {
  name: '首页',
  routePath: '/home/home',
  imgSrc: 'img/tab_bar/home_tab.png',
  activeImgSrc: 'img/tab_bar/home_tab_active.png',
};

export const MY_ORDER_LIST_ITEM = {
  name: '订单',
  routePath: '/home/order',
  imgSrc: 'img/tab_bar/order_tab.png',
  activeImgSrc: 'img/tab_bar/order_tab_active.png',
};

export const FORHERE_LIST_BAR_ITEM = {
  name: '自提',
  routePath: '/home/forhereList',
  type: HOME_CLASSIFY_TYPE_DIC.FORHERE,
  imgSrc: 'img/tab_bar/forhere_tab.png',
  activeImgSrc: 'img/tab_bar/forhere_tab_active.png',
};

export const STORE_BAR_ITEM = {
  name: '门店',
  routePath: '/home/store',
  imgSrc: 'img/tab_bar/shop_tab.png',
  activeImgSrc: 'img/tab_bar/shop_tab_active.png',
};

export const MY_BAR_ITEM = {
  name: '我的',
  routePath: '/home/my',
  imgSrc: 'img/tab_bar/my_tab.png',
  activeImgSrc: 'img/tab_bar/my_tab_active.png',
};
