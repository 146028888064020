import _objectSpread from "/usr/local/src/local-store-mobile/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "real-name-auth-content-wrap"
};
const _hoisted_2 = {
  class: "real-name-auth-form-container"
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center",
    "margin-bottom": "12px"
  }
};
const _hoisted_4 = {
  style: {
    "font-size": "18px",
    "padding-left": "12px"
  }
};
const _hoisted_5 = {
  style: {
    "max-height": "51.8vh",
    "overflow-y": "scroll"
  }
};
const _hoisted_6 = {
  class: "real-name-auth-form-btn-wrap"
};
import { realNameAuth } from '@/api/user';
import { ref, watch, reactive } from 'vue';
import { validator } from '@/util/index';
import OverlayLoading from '@/components/OverlayLoading.vue';
export default {
  __name: 'realNameAuth',
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    viewModel: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['getSuccess', 'cancel-sussess'],
  setup(__props, {
    emit: __emit
  }) {
    const defaultForm = () => ({
      userName: '',
      certId: ''
    });
    const $props = __props;
    const $emit = __emit;
    const form = reactive(_objectSpread(_objectSpread({}, defaultForm()), $props.value));
    const show = ref($props.show);
    const viewModel = ref($props.viewModel);
    const errMsg = ref({});
    const loading = ref(false);
    function closeForm() {
      show.value = false;
      $emit('cancel-sussess');
    }
    function onChange(value) {
      if (typeof value === 'string') value = value.trim();
    }
    function validate() {
      const rules = {
        'userName': {
          message: `请输入姓名`
        },
        'certId': {
          message: `请输入身份证号`
        }
      };
      errMsg.value = validator(form, rules);
      return !Object.keys(errMsg.value).length;
    }
    function submit() {
      if (!validate()) return;
      loading.value = true;
      realNameAuth(_objectSpread({}, form)).then(res => {
        loading.value = false;
        if (res) {
          show.value = false;
          form.value = defaultForm();
          this.setData({
            show: false,
            form: defaultForm()
          });
          $emit('getSuccess');
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return show.value ? (_openBlock(), _createBlock(_component_van_overlay, {
        key: 0,
        show: "",
        "z-index": "99999999"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, " 实名认证" + _toDisplayString(viewModel.value ? '信息' : ''), 1), _createVNode(_component_van_icon, {
          name: "cross",
          style: {
            "font-size": "22px",
            "color": "#999"
          },
          onClick: closeForm
        })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_cell_group, {
          id: "real-name-auth-form",
          border: false
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            label: "姓名",
            modelValue: form.userName,
            "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => form.userName = $event), onChange],
            border: false,
            "input-align": "right",
            "right-icon": viewModel.value ? '' : 'edit',
            required: !viewModel.value,
            readonly: viewModel.value,
            "error-message": errMsg.value.userName,
            placeholder: "请输入姓名"
          }, null, 8, ["modelValue", "right-icon", "required", "readonly", "error-message"]), _createVNode(_component_van_field, {
            label: "身份证号",
            modelValue: form.certId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.certId = $event),
            border: false,
            "input-align": "right",
            "right-icon": viewModel.value ? '' : 'edit',
            required: !viewModel.value,
            readonly: viewModel.value,
            "error-message": errMsg.value.certId,
            placeholder: "请输入身份证号",
            onChange: onChange
          }, null, 8, ["modelValue", "right-icon", "required", "readonly", "error-message"])]),
          _: 1
        })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_button, {
          block: "",
          round: "",
          plain: "",
          type: "primary",
          onClick: closeForm
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(viewModel.value ? '关闭' : '取消'), 1)]),
          _: 1
        }), !viewModel.value ? (_openBlock(), _createBlock(_component_van_button, {
          key: 0,
          block: "",
          round: "",
          type: "primary",
          onClick: submit
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" 确认并提交 ")])),
          _: 1
        })) : _createCommentVNode("", true)])])]), loading.value ? (_openBlock(), _createBlock(OverlayLoading, {
          key: 0
        })) : _createCommentVNode("", true)]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};