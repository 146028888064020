import ThemeConfig from '@/components/ThemeConfig/index.vue';
import PageContainer from '@/components/PageContainer.vue';
import BeautyPrice from '@/components/BeautyPrice.vue';
import DateRangePicker from '@/components/DateRangePicker/DateRangePicker.vue';
import PagingList from '@/components/PagingList.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import realNameAuth from "@/components/realNameAuth/realNameAuth.vue";
import pageLoadState from '@/components/pageLoadState.vue'
import cascaderSelector from '@/components/cascaderSelector.vue'
import addressCascader from '@/components/addressCascader.vue'
import CheckboxPopup from '@/components/CheckboxPopup.vue';
import DateTimePickerGroup from '@/components/DateTimePickerGroup/DateTimePickerGroup.vue';
import VanPullRefreshPagingList from '@/components/VanPullRefreshPagingList.vue';
import BottomTabBarPage from '@/components/BottomTabBarPage.vue';

export default function (app) {
  app.component(ThemeConfig.name, ThemeConfig);
  app.component(PageContainer.name, PageContainer);
  app.component(BeautyPrice.name, BeautyPrice);
  app.component('DateRangePicker', DateRangePicker);
  app.component('PagingList', PagingList);
  app.component('OverlayLoading', OverlayLoading);
  app.component('realNameAuth', realNameAuth);
  app.component('pageLoadState', pageLoadState);
  app.component('cascaderSelector', cascaderSelector);
  app.component('addressCascader', addressCascader);
  app.component('CheckboxPopup', CheckboxPopup);
  app.component('DateTimePickerGroup', DateTimePickerGroup);
  app.component('VanPullRefreshPagingList', VanPullRefreshPagingList);
  app.component('BottomTabBarPage', BottomTabBarPage);
}
