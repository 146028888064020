// 首页分类入口
import {
  listToMap
} from './utils';

export const NORMAL = 'normal'; // 普通类型
export const SELF = 'self'; // 自营商品
export const FORHERE = 'forhere'; // 自提商品
export const DATACENTER = 'dataCenter'; // 自提商品
export const STORE_LIVE = 'storeLive'; // 直播入口

export const HOME_CLASSIFY_TYPE_LIST = [{
  id: NORMAL,
  name: '普通类型',
}, {
  id: SELF,
  name: '自营商品',
}, {
  id: FORHERE,
  name: '自提商品',
}, {
  id: DATACENTER,
  name: '资料中心',
}, {
  id: STORE_LIVE,
  name: '直播入口',
}]

export const HOME_CLASSIFY_TYPE_DIC = {
  NORMAL,
  SELF,
  FORHERE,
  DATACENTER,
  STORE_LIVE,
}

export const HOME_CLASSIFY_TYPE = listToMap(HOME_CLASSIFY_TYPE_LIST);
