import _objectSpread from "/usr/local/src/local-store-mobile/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "20px"
  }
};
const _hoisted_2 = {
  key: 0
};
import { computed, nextTick, ref } from 'vue';
export default {
  __name: 'VanPullRefreshPagingList',
  props: {
    immediateCheck: {
      type: Boolean,
      default: () => true
    },
    getList: {
      type: Function,
      default: () => ({})
    },
    beforeAutoRefresh: {
      type: [Function, null],
      default: null
    },
    beforeRefresh: {
      type: [Function, null],
      default: null
    },
    defaultCurrentPage: {
      type: [Number, String],
      default: 1
    },
    pageSize: {
      type: [Number, String],
      default: 20
    },
    requestPageKey: {
      type: [Object, null],
      default: () => null
    },
    requestParams: {
      type: Object,
      default: () => ({})
    },
    formatterResponse: {
      type: [Function, null],
      default: null
    },
    isSetEmptyOnReLoad: {
      type: Boolean,
      default: () => true
    },
    finishedText: {
      type: String,
      default: '没有更多了'
    },
    page: {
      type: [Number, String],
      default: 1
    },
    dataList: {
      type: Array,
      default: []
    },
    total: {
      type: [Number, String],
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:page', 'update:dataList', 'update:total', 'update:loading', 'refresh', 'requestEnded', 'loadFinished'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const DEFAULT_REQUEST_PAGE_KEY = {
      currentPage: 'current',
      pageSize: 'size'
    };
    function defaultFormatterRes(res) {
      return {
        list: objectGetter(res, 'records'),
        total: objectGetter(res, 'total')
      };
    }
    const $props = __props;
    const $emit = __emit;
    const onLoading = ref(false);
    const finished = ref(null);
    const refreshing = ref(false);
    const page = ref($props.defaultCurrentPage);
    const dataList = ref([]);
    const total = ref(0);
    const pullRefreshStatus = ref('');
    const reqPageKey = computed(() => {
      return _objectSpread(_objectSpread({}, DEFAULT_REQUEST_PAGE_KEY), $props.requestPageKey || {});
    });
    const isLoading = computed({
      get() {
        return onLoading.value;
      },
      set(val) {
        onLoading.value = val;
        $emit('update:loading', onLoading.value);
      }
    });
    const currentPage = computed({
      get() {
        return page.value;
      },
      set(val) {
        page.value = val;
        $emit('update:page', page.value);
      }
    });
    const reqParams = computed(() => {
      const {
        currentPage: pageKey,
        pageSize: sizeKey
      } = reqPageKey.value;
      const {
        pageSize,
        requestParams
      } = $props;
      return _objectSpread({
        [pageKey]: currentPage.value,
        [sizeKey]: pageSize
      }, requestParams || {});
    });
    const data = computed({
      get() {
        return dataList.value;
      },
      set(val) {
        dataList.value = Array.isArray(val) ? val : [];
        $emit('update:dataList', JSON.parse(JSON.stringify(dataList.value)));
      }
    });
    const totalLength = computed({
      get() {
        return total.value;
      },
      set(val) {
        total.value = val || 0;
        $emit('update:total', total.value);
      }
    });
    function objectGetter(obj = {}, keys = '', separator = '.') {
      let keyArr = keys.split(separator);
      let val = JSON.parse(JSON.stringify(obj));
      for (let i = 0; i < keyArr.length; i++) if (typeof val === 'object' && val) val = val[keyArr[i]];else if (i < keyArr.length) return;
      return val;
    }
    function formatterRes(res) {
      if (typeof $props.formatterResponse === 'function') return $props.formatterResponse(res);else return defaultFormatterRes(res);
    }
    function onPullRefreshChange({
      status
    }) {
      pullRefreshStatus.value = status;
    }
    function beforeAutoRefresh(fn) {
      return new Promise(resolve => {
        if (typeof fn === 'function') fn(resolve);else resolve();
      });
    }
    function beforeRefresh(fn) {
      return new Promise(resolve => {
        if (typeof fn === 'function') fn(resolve);else resolve();
      });
    }
    async function onLoad() {
      isLoading.value = true;
      if (refreshing.value || finished.value === null) {
        if ($props.isSetEmptyOnReLoad) data.value = [];
        currentPage.value = $props.defaultCurrentPage;
        refreshing.value = false;
        if (typeof $props.beforeRefresh === 'function') await beforeRefresh($props.beforeRefresh);
      } else if (finished.value === false) {
        currentPage.value++;
      }
      await nextTick();
      let resData = await $props.getList(_objectSpread({}, reqParams.value));
      resData = resData || {};
      $emit('requestEnded', resData);
      const {
        list,
        total
      } = formatterRes(resData);
      let pageList = Array.isArray(list) ? list : [];
      if (currentPage.value === 1) data.value = [...pageList];else data.value = [...data.value, ...pageList];
      totalLength.value = total;
      isLoading.value = refreshing.value = false;
      finished.value = data.value.length >= totalLength.value || !pageList.length;
      if (finished.value) $emit('loadFinished');
    }
    async function onRefresh(isAuto = false) {
      if (isLoading.value) return;
      if (isAuto && typeof $props.beforeAutoRefresh === 'function') {
        await beforeAutoRefresh($props.beforeAutoRefresh);
      }
      refreshing.value = true;
      finished.value = false;
      return onLoad();
    }
    __expose({
      onRefresh
    });
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      return _openBlock(), _createBlock(_component_van_pull_refresh, {
        "model-value": refreshing.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => refreshing.value = $event),
        class: _normalizeClass(pullRefreshStatus.value),
        disabled: isLoading.value,
        onRefresh: _cache[2] || (_cache[2] = $event => onRefresh(true)),
        onChange: onPullRefreshChange
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: isLoading.value,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => isLoading.value = $event),
          finished: finished.value,
          "immediate-check": __props.immediateCheck,
          onLoad: onLoad
        }, {
          finished: _withCtx(() => [_renderSlot(_ctx.$slots, "finished", {
            dataList: dataList.value,
            loading: isLoading.value
          }, () => [dataList.value.length && !isLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.finishedText), 1)) : _createCommentVNode("", true), !dataList.value.length && !isLoading.value ? (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: "暂无相关内容"
          })) : _createCommentVNode("", true)])]),
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
            dataList: dataList.value
          }, () => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, data => {
            return _openBlock(), _createElementBlock("p", _hoisted_1, " Data: " + _toDisplayString(data), 1);
          }), 256))])]),
          _: 3
        }, 8, ["loading", "finished", "immediate-check"])]),
        _: 3
      }, 8, ["model-value", "class", "disabled"]);
    };
  }
};