import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "custom-date-container"
};
const _hoisted_2 = {
  class: "range-btn-container"
};
const _hoisted_3 = {
  style: {
    "width": "47%"
  }
};
const _hoisted_4 = {
  style: {
    "width": "47%"
  }
};
import { computed, ref } from 'vue';
import { showToast } from 'vant';
import { formatYMD, getToday, defaultOpts, isValid } from '@/components/DateTimePickerGroup/util';
export default {
  __name: 'DateTimePickerGroup',
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    zIndex: {
      type: [Number, String],
      default: () => 2000
    },
    initValue: {
      type: Array,
      default: () => []
    },
    confirm: {
      type: [null, Function],
      default: () => null
    },
    minDate: {
      type: [null, Date],
      default: () => defaultOpts().minDate
    },
    maxDate: {
      type: [null, Date],
      default: () => defaultOpts().maxDate
    }
  },
  emits: ['update:show', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const $props = __props;
    const $emit = __emit;
    const selectStartDate = ref(null);
    const selectEndDate = ref(null);
    const showDatePicker = ref(false);
    const datePickerCurrentDate = ref([]);
    const datePickerCurrentTime = ref([]);
    const activeTab = ref(0);
    const pickShow = computed({
      get() {
        const {
          show,
          initValue
        } = $props;
        if (show) {
          if (initValue.length === 2) {
            const [start, end] = initValue;
            selectStartDate.value = formatYMD(start);
            selectEndDate.value = formatYMD(end);
          } else {
            const [startDate, endDate] = getToday();
            initSelect(startDate, endDate);
          }
        }
        return show;
      },
      set(val) {
        $emit('update:show', val);
      }
    });
    let dateKey = '';
    function toSelectDate(key) {
      dateKey = key;
      if (dateKey === 'start') {
        datePickerCurrentDate.value = selectStartDate.value.split(' ')[0].split('-');
        datePickerCurrentTime.value = selectStartDate.value.split(' ')[1].split(':');
      }
      if (dateKey === 'end') {
        datePickerCurrentDate.value = selectEndDate.value.split(' ')[0].split('-');
        datePickerCurrentTime.value = selectEndDate.value.split(' ')[1].split(':');
      }
      activeTab.value = 0;
      showDatePicker.value = true;
    }
    function onDateConfirm(dateTimeData) {
      if (dateKey === 'start') selectStartDate.value = `${dateTimeData[0].selectedValues.join('-')} ${dateTimeData[1].selectedValues.join(':')}`;
      if (dateKey === 'end') selectEndDate.value = `${dateTimeData[0].selectedValues.join('-')} ${dateTimeData[1].selectedValues.join(':')}`;
      showDatePicker.value = false;
    }
    function initSelect(start = null, end = null) {
      selectStartDate.value = start;
      selectEndDate.value = end;
    }
    function reset() {
      initSelect();
      confirm();
    }
    function confirm() {
      if (!isValid(selectStartDate.value, selectEndDate.value)) return showToast({
        position: 'bottom',
        zIndex: 6666,
        message: '开始日期必须小于结束日期'
      });
      let data = [];
      if (selectStartDate.value && selectEndDate.value) data = [selectStartDate.value, selectEndDate.value];
      $emit('confirm', data);
      typeof $props.confirm === 'function' && $props.confirm(data);
      pickShow.value = false;
    }
    return (_ctx, _cache) => {
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      const _component_van_time_picker = _resolveComponent("van-time-picker");
      const _component_van_picker_group = _resolveComponent("van-picker-group");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_popup, {
        show: pickShow.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => pickShow.value = $event),
        class: "date-range-picker-popup",
        position: "bottom",
        closeable: "",
        round: "",
        duration: "0.2",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_cache[12] || (_cache[12] = _createElementVNode("div", {
          class: "date-range-picker-title"
        }, "请选择时间段", -1)), _cache[13] || (_cache[13] = _createElementVNode("div", {
          class: "def-data-title"
        }, [_createElementVNode("span", null, "开始时间"), _createElementVNode("span", null, "结束时间")], -1)), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
          class: "custom-date-wrap",
          onClick: _cache[0] || (_cache[0] = $event => toSelectDate('start'))
        }, _toDisplayString(selectStartDate.value || '开始时间'), 1), _cache[9] || (_cache[9] = _createElementVNode("div", {
          style: {
            "color": "#999"
          }
        }, " ~ ", -1)), _createElementVNode("div", {
          class: "custom-date-wrap",
          onClick: _cache[1] || (_cache[1] = $event => toSelectDate('end'))
        }, _toDisplayString(selectEndDate.value || '结束时间'), 1)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
          type: "default",
          block: "",
          plain: "",
          onClick: _cache[2] || (_cache[2] = $event => reset(null))
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("重置")])),
          _: 1
        })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
          type: "primary",
          block: "",
          onClick: confirm
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("确认")])),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show", "z-index"]), _createVNode(_component_van_popup, {
        show: showDatePicker.value,
        "onUpdate:show": _cache[8] || (_cache[8] = $event => showDatePicker.value = $event),
        round: "",
        position: "bottom",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker_group, {
          "active-tab": activeTab.value,
          "onUpdate:activeTab": _cache[6] || (_cache[6] = $event => activeTab.value = $event),
          title: `请选择${{
            start: '开始',
            end: '结束'
          }[_unref(dateKey)]}时间`,
          tabs: ['选择日期', '选择时间'],
          "next-step-text": "下一步",
          onConfirm: onDateConfirm,
          onCancel: _cache[7] || (_cache[7] = $event => showDatePicker.value = false)
        }, {
          default: _withCtx(() => [_createVNode(_component_van_date_picker, {
            modelValue: datePickerCurrentDate.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => datePickerCurrentDate.value = $event),
            "min-date": __props.minDate,
            "max-date": __props.maxDate,
            formatter: (type, option) => {
              if (type === 'year') option.text += '年';
              if (type === 'month') option.text += '月';
              if (type === 'day') option.text += '日';
              return option;
            }
          }, null, 8, ["modelValue", "min-date", "max-date", "formatter"]), _createVNode(_component_van_time_picker, {
            modelValue: datePickerCurrentTime.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => datePickerCurrentTime.value = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }, 8, ["active-tab", "title"])]),
        _: 1
      }, 8, ["show", "z-index"])], 64);
    };
  }
};