import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-after"];
const _hoisted_2 = ["data-front"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["__price-beautiful__", {
      'del-through': $props.delThrough
    }]),
    style: _normalizeStyle({
      color: $props.color,
      fontSize: $options.computedFontSize[1],
      lineHeight: $options.computedFontSize[0],
      height: $options.computedFontSize[0]
    }),
    "data-after": $props.after
  }, [_createElementVNode("span", {
    style: _normalizeStyle({
      fontSize: $options.computedFontSize[0]
    }),
    "data-front": $props.front
  }, _toDisplayString($options.valueArr[0]), 13, _hoisted_2), _createTextVNode(_toDisplayString($options.valueArr[1] || ''), 1)], 14, _hoisted_1);
}