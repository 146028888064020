const primaryColor = '#1339a0';
const priceColor = '#f6583b';
const orangeColor = '#fa4126';

export default {
  primaryColor,
  warningColor: orangeColor,
  priceColor,
  orange: orangeColor,


  buttonPrimaryBorderColor: primaryColor,
  buttonPrimaryBackgroundColor: primaryColor,
  buttonPrimaryBackground: primaryColor,
  buttonWarningBackground: orangeColor,
  buttonWarningBorderColor: orangeColor,

  buttonDefaultHeight: '.09467rem',
  buttonNormalFontSize: '.03867rem',

  navBarBackground: '#0A2468',
  navBarIconColor: '#fff',
  navBarTextColor: '#fff',
  navBarTitleTextColor: '#fff',

  tabsBottomBarColor: primaryColor,
  tabActiveTextColor: '#333',
  tabTextColor: '#999',
  tabFontSize: '0.039rem',
  tabsLineHeight: '33px',
  tabsBottomBarHeight: '0.0106rem',

  dialogConfirmButtonTextColor: primaryColor,

  pickerConfirmActionColor: primaryColor,

  tabbarItemTextColor: '#999',
  tabbarItemActiveColor: primaryColor,

  cascaderActiveColor: primaryColor,

  gridItemTextFontSize: '.034rem',

  tagPrimaryColor: primaryColor,

  fieldDisabledTextColor: '#666',
  fieldInputDisabledTextColor: '#333',
  uploaderDisabledOpacity: '.9',

  checkboxCheckedIconColor: primaryColor,

  radioCheckedIconColor: primaryColor,

  stepperButtonRoundThemeColor: primaryColor,
  stepperButtonIconColor: '#000',
  stepperInputHeight: '28px',
  // stepperRadius: 0,
  // stepperBackground: '#fff',

  switchOnBackgroundColor: primaryColor,

  dropdownMenuTitleActiveTextColor: primaryColor,
  dropdownMenuOptionActiveColor: primaryColor,
  dropdownMenuTitleTextColor: primaryColor,

  noticeBarHeight: '40px',

  numberKeyboardButtonBackground: primaryColor,

  swipeIndicatorActiveBackground: '#fff',
  swipeIndicatorInactiveBackground: '#fff',

  calendarRangeEdgeBackground: primaryColor,
  calendarRangeMiddleColor: primaryColor,
};
