import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { getAddress } from '@/api/address';
import cascaderSelector from '@/components/cascaderSelector';
import { ref } from 'vue';
export default {
  __name: 'addressCascader',
  props: {
    addressText: {
      type: String,
      default: () => ''
    }
  },
  emits: ['updata-change'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const isTaiWan = areaCode => {
      areaCode = areaCode ? areaCode + '' : '';
      return areaCode.startsWith('71');
    };
    const isXiangGang = areaCode => {
      areaCode = areaCode ? areaCode + '' : '';
      return areaCode.startsWith('81');
    };
    const isAoMen = areaCode => {
      areaCode = areaCode ? areaCode + '' : '';
      return areaCode.startsWith('82');
    };
    const isSanShaShi = areaCode => {
      areaCode = areaCode ? areaCode + '' : '';
      return areaCode.startsWith('4603');
    };
    function getAreaLevelByAreaCode(areaCode) {
      areaCode = areaCode ? areaCode + '' : '';
      if (!areaCode) return null;
      if (areaCode.length >= 9) return 4;else if (areaCode.endsWith('0000')) return 1;else if (areaCode.endsWith('00')) return 2;else return 3;
    }
    const $props = __props;
    const options = {
      onLoad: function (opts) {
        return new Promise(resolve => {
          const data = opts.value ? {
            id: opts.value
          } : {};
          getAddress(data).then(res => {
            resolve(res);
          });
        });
      },
      isLeaf: function (opts) {
        const {
          value
        } = opts;
        if ((isTaiWan(value) || isSanShaShi(value)) && getAreaLevelByAreaCode(value) >= 3) return true;
        if ((isXiangGang(value) || isAoMen(value)) && getAreaLevelByAreaCode(value) >= 2) return true;
        return value.length >= 9;
      }
    };
    const cascaderRef = ref();
    const $emit = __emit;
    function init() {
      cascaderRef.value.init();
    }
    function onChange(e) {
      console.log('eeeeeeeeeeeeeeeeee', e);
      const {
        value,
        selectedOptions
      } = e || {};
      $props.addressText = selectedOptions.map(e => e.name).join(' / '), location.value = selectedOptions[selectedOptions.length - 1].location, $emit('updata-change', {
        value,
        text: selectedOptions.map(e => e.name).join(' / '),
        selectedOptions,
        location: location.value
      });
    }
    __expose({
      init
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(cascaderSelector), {
        class: "__cascader-selector",
        ref_key: "cascaderRef",
        ref: cascaderRef,
        title: "请选择地址",
        options: options,
        onUpdataChange: onChange
      }, null, 512);
    };
  }
};