export const listToMap = (list, opts) => {
  const {
    id = 'id',
      name = 'name',
  } = opts || {};

  const arr = Array.isArray(list) ? list : [];
  return [{}, ...arr].reduce((map, e) => ({
    ...map,
    [e[id]]: e[name],
  }));
}