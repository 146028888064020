import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

import '@/css/common.less';

import vantComponent from '@/plugins/vantComponent';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/image-preview/style';

import customComponent from '@/plugins/customComponent';

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.mount('#app');

vantComponent(app);
customComponent(app);
