import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, unref as _unref, withCtx as _withCtx } from "vue";
import { RouterView } from 'vue-router';
import { computed, ref } from 'vue';
import { useKeepAliveStore } from '@/stores/keepAlive';
export default {
  __name: 'App',
  setup(__props) {
    const $keepAliveStore = useKeepAliveStore();
    const includeList = computed(() => $keepAliveStore.keepAlivePageNameList);
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(RouterView), null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: includeList.value
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include"]))]),
        _: 1
      });
    };
  }
};