import request from '@/plugins/axios';

// 获取一至四级地址
export function getAddress(params, opts = {}) {
  return request({
    url: '/shop/areaInfo/list',
    method: 'get',
    params,
    ...opts,
  });
}

// 地址新增
export function addAddress(data) {
  return request({
    url: '/shop/userAddress',
    method: 'post',
    data
  });
}

// 地址修改
export function editAddress(data = {}, opts = {}) {
  return request({
    url: '/shop/userAddress',
    method: 'put',
    data,
    ...opts,
  });
}

// 地址查询
export function getAddressByPage(params) {
  return request({
    url: '/shop/userAddress/page',
    method: 'get',
    params
  });
}

// 地址id查询
export function queryUserAddressById(params) {
  return request({
    url: `/shop/userAddress/${params.id}`,
    method: 'get',
    params
  });
}

// 地址删除
export function deleteAddress(data) {
  return request({
    url: `/shop/userAddress/${data.id}`,
    method: 'delete',
    data
  });
}




// 新增退货地址
export function addAdminAddress(data = {}, opts = {}) {
  return request({
    url: '/shop/returnAddress',
    method: 'post',
    data,
    ...opts,
  });
}

// 修改退货地址
export function editAdminAddress(data = {}, opts = {}) {
  return request({
    url: '/shop/returnAddress',
    method: 'put',
    data,
    ...opts,
  });
}

// 通过id查询退货地址
export function queryAdminAddress(params = {}, opts = {}) {
  return request({
    url: `/shop/returnAddress/${params.id}`,
    method: 'get',
    params,
    ...opts,
  });
}

// 删除退货地址
export function delAdminAddress(data = {}, opts = {}) {
  return request({
    url: `/shop/returnAddress/${data.id}`,
    method: 'delete',
    data,
    ...opts,
  });
}

// 退货地址查询
export function getAdminAddressPage(params = {}, opts = {}) {
  return request({
    url: `/shop/returnAddress/page`,
    method: 'get',
    params,
    ...opts,
  });
}

// 根据坐标获取区域
export function getLocation(params = {}) {
  return request({
    url: `/shop/areaInfo/location`,
    method: 'get',
    params,
  });
}
