import { ref, computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import {
  defStoreInfoForm,
  formatStoreManagementMenus,
  hasEntry,
  hasForHere,
  SAVE_STORE_INFO, GET_STORE_INFO,
  SAVE_MENUS_DATA, GET_MENUS_DATA,
  SAVE_USER_FUNC, GET_USER_FUNC, GET_SCAN_DESK_INDEX, SAVE_SCAN_DESK_INDEX,
} from '@/stores/permission/util';
import { getAdminMenus, getUserFuncList } from '@/api/system';
import { getStoreInfo } from '@/api/store';
import { ORDER_TYPE_DIC } from '@/const/dictionary/ORDER_TYPE';
import { PAY_LINE_TYPE_DIC } from '@/const/dictionary/PAY_LINE_TYPE';

const TAB_BAR = require('../../const/dictionary/TAB_BAR_ITEMS');

export const usePermissionStore = defineStore(`permissionStore`, () => {
  const storeLoading = ref(false);
  const storeInfo = reactive(defStoreInfoForm());
  const menusLoading = ref(false);
  const menusResponseData = ref([]);
  const funcLoading = ref(false);
  const userFunc = ref([]);
  const forhereBarItem = reactive({ ...TAB_BAR.FORHERE_LIST_BAR_ITEM });
  const deskIndex = ref(GET_SCAN_DESK_INDEX());

  const loading = computed(() => storeLoading.value || menusLoading.value || funcLoading.value);
  const isEnableStore = computed(() => +storeInfo.enable === 1);
  const isShowStoreTab = computed(() => hasEntry(menusResponseData.value));
  const isShowForHereTab = computed(() => hasForHere(userFunc.value));
  const homeTabsList = computed(() => {
    const storeBar = isEnableStore.value && isShowStoreTab.value ? [TAB_BAR.STORE_BAR_ITEM] : [];
    const forHereBar = isEnableStore.value && isShowForHereTab.value ? [{ ...forhereBarItem }] : [];
    const orderBar = isEnableStore.value ? [TAB_BAR.MY_ORDER_LIST_ITEM] : [];
    return [
      TAB_BAR.HOME_BAR_ITEM,
      ...orderBar,
      ...forHereBar,
      ...storeBar,
      TAB_BAR.MY_BAR_ITEM,
    ];
  });
  const storeMenus = computed(() => formatStoreManagementMenus(menusResponseData.value));
  const scanDeskIndex = computed({
    get() {
      return deskIndex.value;
    },
    set(val) {
      deskIndex.value = SAVE_SCAN_DESK_INDEX(val);
    },
  });
  const funcList = computed(() => userFunc.value.map(e => e['menuCode']));
  // 支付方式权限
  const onlineOrderTypeFunc = computed(() => {
    return [
      ORDER_TYPE_DIC.EXPRESS,
      ORDER_TYPE_DIC.DELIVERY,
    ].filter(fun => funcList.value.includes(fun));
  });
  const offlineOrderTypeFunc = computed(() => {
    return [
      ORDER_TYPE_DIC.FORHERE,
      ORDER_TYPE_DIC.PACKAGE,
    ].filter(fun => funcList.value.includes(fun));
  });
  const lineOrderTypeFunc = computed(() => {
    const online = onlineOrderTypeFunc.value.length ? [PAY_LINE_TYPE_DIC.ONLINE] : [];
    const offline = offlineOrderTypeFunc.value.length ? [PAY_LINE_TYPE_DIC.OFFLINE] : [];
    return [...online, ...offline];
  });
  const isScanDeskIndexForhere = computed(() => offlineOrderTypeFunc.value.includes(ORDER_TYPE_DIC.FORHERE) && scanDeskIndex.value !== null);
  const defaultOnlineOrderType = computed(() => onlineOrderTypeFunc.value[0] || null);
  const defaultOfflineOrderType = computed(() => {
    if (isScanDeskIndexForhere.value)
      return ORDER_TYPE_DIC.FORHERE;
    return offlineOrderTypeFunc.value[0] || null;
  });
  const defaultLineOrderType = computed(() => {
    if (isScanDeskIndexForhere.value)
      return PAY_LINE_TYPE_DIC.OFFLINE;
    return defaultOnlineOrderType.value ? PAY_LINE_TYPE_DIC.ONLINE : defaultOfflineOrderType.value ? PAY_LINE_TYPE_DIC.OFFLINE : null;
  });
  const defaultOrderType = computed(() => {
    if (isScanDeskIndexForhere.value)
      return ORDER_TYPE_DIC.FORHERE;
    return defaultOnlineOrderType.value || defaultOfflineOrderType.value || null;
  });


  function setStoreInfo(data) {
    data = data || {};
    for (const key in defStoreInfoForm())
      storeInfo[key] = data.hasOwnProperty(key) ? data[key] : null;
    SAVE_STORE_INFO({ ...storeInfo });
  }

  function initStoreInfo() {
    if (storeLoading.value) return;
    storeLoading.value = true;
    return getStoreInfo().then(res => {
      setStoreInfo(res);
      storeLoading.value = false;
    });
  }

  function setMenusResponseData(data) {
    menusResponseData.value = Array.isArray(data) ? data : [];
    SAVE_MENUS_DATA(menusResponseData.value);
  }

  async function initMenusData() {
    menusLoading.value = true;
    await initStoreInfo();
    return getAdminMenus({ storeId: storeInfo.id }).then(res => {
      setMenusResponseData(res);
      menusLoading.value = false;
    });
  }


  function setUserFuncList(data) {
    userFunc.value = Array.isArray(data) ? data : [];
    SAVE_USER_FUNC(userFunc.value);
  }

  function initUserFuncData() {
    funcLoading.value = true;
    return getUserFuncList().then(res => {
      // res = res.filter(e => ![
      //   'package',
      //   // 'forhere',
      //   // 'express',
      //   // 'delivery',
      // ].includes(e.menuCode))
      setUserFuncList(res);
      funcLoading.value = false;
    });
  }

  function getHasMenuByName(name) {
    return initMenusData().then(() => {
      return name && menusResponseData.value.some(e => e.menuName === name);
    });
  }

  function getHasFuncByMenuCode(menuCode) {
    return initUserFuncData().then(() => {
      return menuCode && funcList.value.some(e => e === menuCode);
    });
  }

  function init() {
    return Promise.all([initMenusData(), initUserFuncData()]);
  }

  setStoreInfo(GET_STORE_INFO());
  setMenusResponseData(GET_MENUS_DATA());
  setUserFuncList(GET_USER_FUNC());

  return {
    loading,
    storeLoading,
    menusLoading,
    funcLoading,

    storeInfo,
    isEnableStore,

    homeTabsList,
    isShowStoreTab,
    isShowForHereTab,
    forhereBarItem,

    menusResponseData,

    storeMenus,

    userFunc,
    funcList,
    onlineOrderTypeFunc,
    offlineOrderTypeFunc,
    lineOrderTypeFunc,
    defaultOnlineOrderType,
    defaultOfflineOrderType,
    defaultLineOrderType,
    defaultOrderType,

    scanDeskIndex,

    init,
    initStoreInfo,
    initMenusData,
    initUserFuncData,
    getHasMenuByName,
    getHasFuncByMenuCode,
  };
});
