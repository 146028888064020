import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
export default {
  __name: 'OverlayLoading',
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    zIndex: {
      type: [Number, String],
      default: () => 2000
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.loading,
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_loading, {
          color: "#fff"
        })])]),
        _: 1
      }, 8, ["show", "z-index"]);
    };
  }
};