import { useRouter } from 'vue-router';

const isNavigator = pattern => new RegExp(pattern, 'i').test(navigator.userAgent.toLowerCase());


export default () => {
  const $router = useRouter();

  const PAY_DATA = 'pany_pay_data';

  function isWeiXin() {
    return isNavigator(/micromessenger/);
  }

  function isAlipay() {
    return isNavigator(/alipay/);
  }

  function setPayData(data) {
    data = data || {};
    window.sessionStorage.setItem(PAY_DATA, JSON.stringify(data));
  }

  function getPayData() {
    return JSON.parse(window.sessionStorage.getItem(PAY_DATA));
  }

  function toPay(data) {
    setPayData(data);
    $router.replace({
      path: '/toPay'
    });
  }

  return {
    isWeiXin, isAlipay,
    setPayData,
    getPayData,
    toPay,
  };
}
