import { listToMap } from './utils';

const ONLINE = 'online'; // 线上
const OFFLINE = 'offline'; // 线下
export const PAY_LINE_TYPE_DIC = { ONLINE, OFFLINE };
export const PAY_LINE_TYPE_LIST = [
  { id: OFFLINE, name: '自提' },
  { id: ONLINE, name: '配送' },
];
export const PAY_LINE_TYPE = listToMap(PAY_LINE_TYPE_LIST);
