import _objectSpread from "/usr/local/src/local-store-mobile/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "bottom-tab-bar-page-container"
};
const _hoisted_2 = {
  class: "home-tabs-page-container"
};
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'BottomTabBarPage',
  props: {
    tabList: {
      type: Array,
      default: () => []
    },
    beforeRouterReplace: {
      type: [Function, null],
      default: null
    }
  },
  emits: ['update:loading', 'reClick'],
  setup(__props, {
    emit: __emit
  }) {
    const $props = __props;
    const DEFAULT_ITEM = {
      icon: '',
      activeIcon: '',
      imgSrc: '',
      activeImgSrc: '',
      name: '',
      routePath: '',
      dot: false,
      badge: undefined,
      badgeProps: {}
    };
    const $emit = __emit;
    const $router = useRouter();
    const $route = useRoute();
    const tabsList = computed(() => {
      const list = Array.isArray($props.tabList) ? $props.tabList : [];
      return list.map(e => _objectSpread(_objectSpread({}, DEFAULT_ITEM), e || {}));
    });
    const tabsActive = computed(() => {
      return tabsList.value.findIndex(({
        routePath
      }) => routePath === $route.path);
    });
    function beforeRouterReplace(fn, toPath) {
      return new Promise(done => {
        if (typeof fn === 'function') fn(toPath, done);else done();
      });
    }
    async function onClick(tab) {
      if (tab.routePath === $route.path) $emit('reClick', _objectSpread({}, tab));else {
        if (typeof $props.beforeRouterReplace === 'function') await beforeRouterReplace($props.beforeRouterReplace, tab.routePath);
        $router.replace(tab.routePath).then();
      }
    }
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      const _component_theme_config = _resolveComponent("theme-config");
      return _openBlock(), _createBlock(_component_theme_config, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")]), _createVNode(_component_van_tabbar, {
          "model-value": tabsActive.value,
          class: "bottom-tab-bar-item",
          border: false,
          fixed: false
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsList.value, tab => {
            return _openBlock(), _createBlock(_component_van_tabbar_item, {
              key: tab.routePath,
              dot: tab.dot,
              badge: tab.badge,
              "badge-props": tab.badgeProps,
              onClick: $event => onClick(tab)
            }, {
              icon: _withCtx(props => [_withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(tab.activeImgSrc ? 'van-image' : 'van-icon'), {
                class: "bottom-tab-bar-img",
                src: tab.activeImgSrc,
                fit: "contain",
                name: tab.activeIcon
              }, {
                loading: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("span", null, null, -1)])),
                _: 2
              }, 1032, ["src", "name"])), [[_vShow, props.active]]), _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(tab.imgSrc ? 'van-image' : 'van-icon'), {
                class: "bottom-tab-bar-img",
                src: tab.imgSrc,
                fit: "contain",
                name: tab.icon
              }, {
                loading: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("span", null, null, -1)])),
                _: 2
              }, 1032, ["src", "name"])), [[_vShow, !props.active]])]),
              default: _withCtx(() => [_createTextVNode(_toDisplayString(tab.name) + " ", 1)]),
              _: 2
            }, 1032, ["dot", "badge", "badge-props", "onClick"]);
          }), 128))]),
          _: 1
        }, 8, ["model-value"])])]),
        _: 3
      });
    };
  }
};