import * as CryptoJS from 'crypto-js';
import moment from 'moment';

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach(ele => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding
        });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

// 表单序列化
export const serialize = data => {
  let list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ ele }=${ data[ele] }`);
  });
  return list.join('&');
};

// 获取url中参数对象
export function getLocationSearch(locationSearch) {
  let p = {};
  locationSearch = locationSearch || window.location.search;
  let search = locationSearch.split('?')[1] || '';
  search.split('&').forEach(e => {
    let [k, v] = e.split('=');
    p[k] = v;
  });
  return p;
}

// 安全获取对象的值
export const objectGetter = (obj = {}, keys = '', separator = '.') => {
  let keyArr = keys.split(separator);
  let val = JSON.parse(JSON.stringify(obj));
  for (let i = 0; i < keyArr.length; i++)
    if (typeof val === 'object' && val)
      val = val[keyArr[i]];
    else if (i < keyArr.length)
      return;
  return val;
};

export const throttle = (fn, delay, deBounce) => {
  delay = delay || 1000 / 60;
  let now, lastExec, timer, context, args;
  let execute = function () {
    fn.apply(context, args || []);
    lastExec = now;
  };
  return function () {
    context = this;
    if (deBounce) {
      clearTimeout(timer);
      timer = setTimeout(execute, delay);
      return;
    }
    args = Array.prototype.slice.apply(arguments);
    now = new Date().getTime();
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    if (lastExec) {
      let diff = delay - (now - lastExec);
      if (diff < 0)
        execute();
      else
        timer = setTimeout(execute, diff);
    } else
      execute();
  };
};

export const objListToMap = (list, opts) => {
  const {
    id = 'id',
    name = 'name',
  } = opts || {};

  const arr = Array.isArray(list) ? list : [];
  return [{}, ...arr].reduce((map, e) => ({
    ...map,
    [e[id]]: name ? e[name] : e,
  }));
};

export const toFixed2 = number => {
  number = +number || 0;
  number = +number.toFixed(2);
  number = number === 0 ? 0 : number;
  return number;
};

export function moneyToFixed2(money = '', forceFractionDigits = false) {
  if (['', null, undefined].includes(money))
    return '';

  if (isNaN(+money))
    return money;

  if (+money === 0)
    return forceFractionDigits ? '0.00' : 0;

  return (+money).toFixed(2);
}

export function getDisplayPrice(goods, isVipUser) {
  return isVipUser ? goods.vipPrice || goods.salePrice : goods.salePrice;
}

export function getDisplayPrice2(goods, isVipUser) {
  return isVipUser ? goods.vipPrice || goods.goodsPrice : goods.goodsPrice;
}

export function mobileFormat(mobile) {
  mobile = mobile ? mobile + '' : '';
  if (!mobile)
    return '';
  return [mobile.substring(0, 3), mobile.substring(3, 7), mobile.substring(7, 11)
  ].join('-');
}

const def_keys = ['createTime', 'divideTime', 'paidTime', 'applyTime'];
export const formatTimeSearchQuery = (data = {}, keys = def_keys) => {
  for (const key in data) {
    if (keys.includes(key)) {
      data[`${ key }Search`] = data[key];
    }
  }
  return data;
};

export const REGULAR = {
  '整数': /^-?\d+$/,
  '正整数': /^[1-9]\d*$/, // 不含0
  '非负整数': /^([0]|[1-9][0-9]*)$/, // 0 1 2 ...
  '非负数': /^\d+(\.{0,1}\d+){0,1}$/, // 0 1 1.1 ...
  '价格': /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, // 含0
  '手机号': /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
};

export const validator = (data, rules) => {
  if (!rules || rules.toString() !== '[object Object]')
    return;

  if (!data || data.toString() !== '[object Object]')
    return;

  const keys = Object.keys(rules);

  const toVali = (rule, data, form) => {
    if (!rule || rule.toString() !== '[object Object]')
      return;

    const {
      validate,
      message,
    } = rule;

    if (!message)
      return;

    let isVali = false;

    if (typeof validate === 'function')
      isVali = validate(data, form);
    else
      isVali = !!data || data === 0 || (Array.isArray(data) && data.length);

    if (!isVali)
      return message;
  };

  let errMsgData = {};

  // console.log('keys', keys, data)

  keys.forEach(key => {
    // console.log(key)

    const valiData = objectGetter(data, key);
    const rule = rules[key];
    // validate
    if (Array.isArray(rule)) {
      for (let i = 0; i < rule.length; i++) {
        const msg = toVali(rule[i], valiData, data);
        if (msg) {
          // console.log(msg)
          errMsgData[key] = msg;
          break;
        }
      }
    } else if (typeof rule === 'object') {
      const msg = toVali(rule, valiData, data);
      if (msg) {
        errMsgData[key] = msg;
      }
    }

    // console.log(`valiData`, valiData)
  });

  return errMsgData;
};

export const getEffectText = (item, RELATIVE, ABSOLUTE) => {
  if (item.effectMode && item.effectMode === RELATIVE)
    return '领取后' + item.effectDays + '天内有效';
  if (item.effectMode && item.effectMode === ABSOLUTE)
    return item.effectStart.split(' ')[0] + '至' + item.effectEnd.split(' ')[0];
};

export const getUseGoodsBtnText = (item, INCLUDE, EXCLUDE) => {
  if (item.useType && item.useType === INCLUDE)
    return '可用商品';
  if (item.useType && item.useType === EXCLUDE)
    return '不可用商品';
};

export const formatIsDefIconName = (item) => {
  return +item['defaultAddress'] === 1 ? 'checked' : 'circle';
};

export const displayLink = (item, GOODS) => {
  return item.bannerType === GOODS ? '已关联商品' : '';
};

export const isFirst = (index) => {
  return !index;
};

export const isLast = (index, dataList) => {
  return index === dataList.length - 1;
};

export const isSelected = (item, selectList, PRIMARY_KEY) => {
  return selectList.some(function (e) {
    return e[PRIMARY_KEY] === item[PRIMARY_KEY];
  });
};

export const isMultiple = (multipleModel, selectList) => {
  return multipleModel && selectList.length;
};

export const isRecommend = (goods) => {
  return goods['isRecommend'] == 1;
};

export const total = (selectList) => {
  var p = 0;
  var s = 0;
  selectList.forEach(function (goods) {
    if (goods['storeId'] == 0)
      p++;
    else
      s++;
  });
  return { P: p || null, S: s || null };
};

export const clearCookie = () => {
  try {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  } catch (e) {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      var cookiePath = location.pathname.replace(/^\//, '');
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=' + cookiePath + ';';
    }
  }
};

/**
 * @description 手机号脱敏
 * @param {string} phone 手机号
 * @return {string} 123****7890
 * @example phoneDesensitization('12345678901') // 123****7890
 */
export const phoneDesensitization = phone => {
  if ([undefined, null].includes(phone))
    return phone;
  return (phone + '').replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
};

/**
 * @description 身份证号脱敏
 * @param {string} idCard 身份证号
 * @return {string} 123****7890
 * @example idCardDesensitization('123456789123456789') // 1234****6789
 */
export const idCardDesensitization = idCard => {
  if ([undefined, null].includes(idCard))
    return idCard;
  return (idCard + '').replace(/^(.{6})(?:\d+)(.{4})$/, '$1******$2');
};

/**
 * @description 姓名脱敏
 * @param {string} name 姓名
 */
export const nameDesensitization = name => {
  if ([undefined, null].includes(name))
    return name;
  return (name + '').replace(/^(.).*(.)$/, (_, $1, $2) => (name.length > 2 ? `${ $1 }**` : `*${ $2 }`));
};

export function base64ToBlob(base64) {
  let arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// 加密函数
export function encryptData(data) {
  return CryptoJS.AES.encrypt(data, `pany_PANY_kasdjfasldkfj`).toString();
}

// 解密函数
export function decryptData(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, `pany_PANY_kasdjfasldkfj`);
  return bytes.toString(CryptoJS.enc.Utf8);
}

// 加密
// var encryptedData = encryptData(dataToEncrypt);
// console.log('Encrypted Data:', encryptedData);
//
// // 解密
// var decryptedData = decryptData(encryptedData);
// console.log('Decrypted Data:', decryptedData);

export const isNavigator = pattern => new RegExp(pattern, 'i').test(navigator.userAgent.toLowerCase());

export function isWeiXin() {
  // return true;
  return isNavigator(/micromessenger/);
}

export function timeAgo(time) {
  const targetTimestamp = moment(time).valueOf();
  if (isNaN(targetTimestamp))
    return '';

  const nowTimestamp = Date.now();
  const diff = nowTimestamp - targetTimestamp;
  if (diff < 0)
    return '';

  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 31;
  const year = day * 365;

  if (diff >= year)
    return Math.floor(diff / year) + '年前';
  if (diff >= month)
    return Math.floor(diff / month) + '个月前';
  if (diff >= day)
    return Math.floor(diff / day) + '天前';
  if (diff >= hour)
    return Math.floor(diff / hour) + '小时前';
  if (diff >= minute)
    return Math.floor(diff / minute) + '分钟前';
  return '刚刚';
}
