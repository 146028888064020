import Compressor from './compressor.min';

export default function (file) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1280,
      maxHeight: 1280,
      quality: 0.8,
      convertTypes: ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'],
      convertSize: 800000,
      success(result) {
        let _file = new File([result], file.name, { type: file.type });

        resolve(_file);
      },
      error(err) {
        reject(err);
      }
    });
  });
}
