export default {
  name: 'BeautyPrice',
  props: {
    value: {
      default: () => ''
    },
    fill: {
      type: Boolean,
      default: () => true
    },
    fontSize: {
      type: Array,
      default: () => [18, 13]
    },
    color: {
      type: String,
      default: () => 'var(--van-price-color)'
    },
    delThrough: {
      type: Boolean,
      default: () => false
    },
    front: {
      type: String,
      default: () => '￥'
    },
    after: {
      type: String,
      default: () => ''
    },
    clientWidthReferenceValue: {
      type: Number,
      default: () => 375
    }
  },
  computed: {
    valueArr() {
      let value = this.toFixed2(this.value) + '';
      let [front, after] = value.split('.');
      let a = ((after || 0) + '0').slice(0, 2);
      a = !this.fill && a == 0 ? '' : a;
      a !== '' && (front += '.');
      return [front, a];
    },
    computedFontSize() {
      const [front, after] = [...this.fontSize];
      return [this.formatSize(front), this.formatSize(after || front)];
    }
  },
  methods: {
    toFixed2(number) {
      number = +number || 0;
      number = +number.toFixed(2);
      number = number === 0 ? 0 : number;
      return number;
    },
    formatSize(size) {
      size = size || '';
      size += '';
      if (!size.includes('rem') && !size.includes('em')) size = parseFloat(size) / this.clientWidthReferenceValue + 'rem';
      return size;
    }
  }
};