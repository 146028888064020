import _objectSpread from "/usr/local/src/local-store-mobile/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "padding": "var(--van-popup-close-icon-margin)",
    "line-height": "20px",
    "font-size": "16px",
    "color": "#333"
  }
};
const _hoisted_2 = {
  class: "__cascader-selector-container"
};
const _hoisted_3 = {
  style: {
    "height": "61.8vh",
    "overflow-y": "scroll"
  }
};
const _hoisted_4 = ["onClick"];
import { reactive, ref, nextTick } from 'vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
export default {
  __name: 'cascaderSelector',
  props: {
    title: {
      type: String,
      default: () => '请选择'
    },
    valueKey: {
      default: () => 'id'
    },
    labelKey: {
      default: () => 'name'
    },
    options: {
      type: Object,
      default: () => ({})
    },
    swipeable: {
      type: Boolean,
      default: () => true
    },
    ellipsis: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['change'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const default_obj = () => ({
      value: '',
      selectedOptions: {},
      tabIndex: 0
    });
    const $props = __props;
    const $emit = __emit;
    const default_placeholder = ref('请选择');
    const show = ref(false);
    const active = ref(0);
    const dataList = ref([]);
    const checkList = ref([]);
    const loading = ref(false);
    const checkList2 = ref([{}]);
    const tabs = ref();
    async function init(refresh = true) {
      let onLoadFn = $props.options.onLoad;
      onLoadFn = typeof onLoadFn === 'function' ? onLoadFn : () => [];
      show.value = true;
      if (!dataList.value.length || refresh) {
        loading.value = true;
        const list = await onLoadFn(default_obj());
        loading.value = false;
        dataList.value = [Array.isArray(list) ? list : []];
        console.log('dataListdataListdataList', dataList);
        checkList.value = [];
        active.value = 0;
      }
      nextTick(() => {
        show.value = true;
      });
    }
    function onOptionSelect(tabIndex, index) {
      // return
      let onLoadFn = $props.options.onLoad;
      onLoadFn = typeof onLoadFn === 'function' ? onLoadFn : () => [];
      let isLeafFn = $props.options.isLeaf;
      isLeafFn = typeof isLeafFn === 'function' ? isLeafFn : () => false;

      // checked item
      const checked = dataList.value[tabIndex][index];
      console.log(`checked`, checked);

      // checked array
      let checkListItem = Array.isArray(checkList.value) ? checkList.value : [];
      checkListItem[tabIndex] = _objectSpread({}, checked);
      checkList.value = checkListItem;
      const opts = {
        value: checked[$props.valueKey],
        selectedOptions: checkList,
        tabIndex
      };
      const isLeaf = isLeafFn(opts);
      if (isLeaf) {
        let dataListItem = Array.isArray(dataList.value) ? [...dataList.value] : [];
        dataList.value = dataListItem.splice(tabIndex + 1);
        checkList.value = Array.isArray(checkList.value) ? [...checkList.value] : [];
        checkList.value.splice(tabIndex + 1, checkList.value.length);
        active.value = tabIndex;
        show.value = false;
        return $emit('updata-change', {
          value: checkList.value.map(e => e[$props.valueKey]),
          selectedOptions: checkList.value
        });
      }
      loading.value = true;
      onLoadFn(opts).then(res => {
        loading.value = false;
        let list = Array.isArray(res) ? res : [];
        let dataListItem = Array.isArray(dataList.value) ? dataList.value : [];
        dataList.value = [...dataListItem.slice(0, tabIndex + 1), list];
        checkList.value = Array.isArray(checkList.value) ? [...checkList.value] : [];
        checkList.value.splice(tabIndex + 1, checkList.value.length);
        tabs.value.scrollTo(tabIndex + 1);
      });
    }
    function onClose() {
      console.log('onCloseonCloseonClose');
      show.value = false;
    }
    __expose({
      init
    });
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => show.value = $event),
        position: "bottom",
        style: {
          height: '60%'
        },
        closeable: "",
        onClickCloseIcon: onClose
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_tabs, {
          active: active.value,
          "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
          ref_key: "tabs",
          ref: tabs
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, (tab, tabIndex) => {
            return _openBlock(), _createBlock(_component_van_tab, {
              key: tabIndex,
              title: checkList.value[tabIndex] ? checkList.value[tabIndex][__props.labelKey] : default_placeholder.value
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab, (item, index) => {
                return _openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(['cascader__option']),
                  onClick: $event => onOptionSelect(tabIndex, index)
                }, [_createElementVNode("text", null, _toDisplayString(item[__props.labelKey]), 1), _createVNode(_component_van_icon, {
                  name: "success"
                })], 8, _hoisted_4);
              }), 128))])]),
              _: 2
            }, 1032, ["title"]);
          }), 128))]),
          _: 1
        }, 8, ["active"])]), _createVNode(OverlayLoading, {
          loading: loading.value
        }, null, 8, ["loading"])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};