import request from '@/plugins/axios';

// 店铺信息
export function getStoreInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/storeInfo/getStore`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取小程序二维码
export function getMinicode() {
  return request({
    url: `/shop/admin/store/minicode`,
    method: 'get',
  })
}

// 可切换店铺列表
export function getChangeStoreList() {
  return request({
    url: `/shop/storeInfo/list`,
    method: 'get',
  })
}

// 注册门店信息
export function getRegistryStoreInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/storeInfo/registry`,
    method: 'get',
    params,
    ...opts,
  });
}

// 注册门店
export function registryStore(data = {}, opts = {}) {
  return request({
    url: `/shop/storeInfo/registry`,
    method: 'post',
    data,
    ...opts,
  });
}

// 门店信息详情
export function getAdminStoreDetail(params = {}, opts = {}) {
  return request({
    url: `/shop/admin/store/detail`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取系统配置值 -get
// /shop/storeSetting/query
// 参数： key
export function queryStoreSetting(params = {}, opts = {}) {
  return request({
    url: `/shop/storeSetting/query`,
    method: 'get',
    params,
    ...opts,
  });
}
// 切换店铺
export function switchStore(storeId) {
  return request({
    url: `/shop/storeInfo/switchStore/${storeId}`,
    method: 'get',
  });
}

// 根据店铺编码 storeCode，切换店铺
export function switchStoreByCode(params = {}, opts = {}) {
  return request({
    url: `/shop/storeInfo/switchStoreByCode`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取店铺商户信息
export function getStoreInMerchantInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/storeInfo/getMerchantInfo`,
    method: 'get',
    params,
    ...opts,
  });
}
