import moment from 'moment';

const DAY_MS = 24 * 60 * 60 * 1000;
export const formatDay = date => moment(date).format('YYYY-MM-DD');
export const formatYMD = date => moment(date).format('YYYY-MM-DD HH:mm');

export const getToday = () => {
    const day = formatDay(new Date());
    return [day + ' 00:00', day + ' 23:59'];
};

export const defaultOpts = () => {
    const NOW = new Date().getTime();
    return {
        minDate: moment(NOW - (5 * 365 * DAY_MS)).toDate(),
        maxDate: moment(NOW + (365 * DAY_MS)).toDate(),
    };
};

export const isValid = (startDate, endDate) => {
    if (startDate && endDate)
        return moment(startDate).valueOf() < moment(endDate).valueOf();
    else
        return true;
};
