import { FORHERE } from '@/const/dictionary/ORDER_TYPE';

const USER_STORE_INFO = 'pany_userStoreInfo';
const MENUS_DATA = 'pany_menusData';
const USER_FUNC = 'pany_userFunc';
const SCAN_DESK_INDEX = 'pany_scanDeskIndex';
const MENU_TYPE = {
  GROUP: 'group', // 门店菜单组名称类
  ENTRY: 'entry', // 门店功能入口类
};
const SORT_KEY = 'sort';
const SORT_FN = (a, b) => {
  return +a[SORT_KEY] - +b[SORT_KEY];
};

export const defStoreInfoForm = () => ({
  address: null, // null,
  cityCode: null, // "",
  cityName: null, // "佛山市",
  contact: null, // "18022764913",
  content: null, // "经营无糖，无淀粉烘焙食品。",
  countryCode: null, // "",
  countryName: null, // "顺德区",
  enable: null, // 1,
  id: null, // 2,
  latitude: null, // "",
  longitude: null, // "",
  provinceCode: null, // "",
  provinceName: null, // "广东省",
  storeBackground: null, // "https://resale-test.shop.payol.cn/download/shopBg/20230327165038822YYkT.jpg",
  storeCode: null, // "LSF-00001",
  storeLogo: null, // "https://resale-test.shop.payol.cn/download/logo/20230327165022754uezQ.jpg",
  storeName: null, // "乐食枋（营运总店）",
  storeQrcode: null, // null,
  street: null, // "",
  warn: null, // null,
});

export const SAVE_STORE_INFO = storeInfo => {
  window.sessionStorage.setItem(USER_STORE_INFO, JSON.stringify(storeInfo || {}));
};
export const GET_STORE_INFO = () => {
  return JSON.parse(window.sessionStorage.getItem(USER_STORE_INFO)) || {};
};
export const SAVE_MENUS_DATA = menusData => {
  window.sessionStorage.setItem(MENUS_DATA, JSON.stringify(menusData || []));
};
export const GET_MENUS_DATA = () => {
  return JSON.parse(window.sessionStorage.getItem(MENUS_DATA)) || [];
};
export const SAVE_USER_FUNC = userFunc => {
  window.sessionStorage.setItem(USER_FUNC, JSON.stringify(userFunc || []));
};
export const GET_USER_FUNC = () => {
  return JSON.parse(window.sessionStorage.getItem(USER_FUNC)) || [];
};
export const SAVE_SCAN_DESK_INDEX = deskIndex => {
  deskIndex = [undefined, ''].includes(deskIndex) ? null : deskIndex;
  window.sessionStorage.setItem(SCAN_DESK_INDEX, JSON.stringify(deskIndex));
  return deskIndex;
};
export const GET_SCAN_DESK_INDEX = () => {
  const deskIndex = JSON.parse(window.sessionStorage.getItem(SCAN_DESK_INDEX));
  return [undefined, ''].includes(deskIndex) ? null : deskIndex;
};


export function formatStoreManagementMenus(menusDataList) {
  let menus = Array.isArray(menusDataList) ? menusDataList : [];
  let menusLength = menus.length;
  let menuGroup = {};
  let groupNameMap = {};
  while (menusLength--) {
    const item = {
      ...menus[menusLength]
    };

    const isMenu = [MENU_TYPE.GROUP, MENU_TYPE.ENTRY].includes(item.type);
    if (isMenu) {
      const { id, type, menuName, menuCode, menuIcon, sort } = item;

      let pid = item.pid;
      pid = type === MENU_TYPE.ENTRY && [null, undefined, ''].includes(pid) ? '其它' : pid;

      if (type === MENU_TYPE.GROUP)
        groupNameMap[id] = menuName;

      if (type === MENU_TYPE.ENTRY) {
        if (!menuGroup[pid])
          menuGroup[pid] = {
            id: pid,
            'groupName': '',
            'itemList': [],
            sort,
          };

        menuGroup[pid]['itemList'] = [
          ...menuGroup[pid]['itemList'],
          {
            id,
            'name': menuName,
            'url': menuCode,
            'imgSrc': menuIcon.startsWith(`../../`) ? menuIcon.replace('../../', '') : menuIcon,
            sort,
          }
        ];
      }
    }
  }

  const keys = Object.keys(menuGroup).sort(SORT_FN);
  menuGroup = [
    [], ...keys
  ].reduce((arr, key) => [
    ...arr,
    menuGroup[key]
  ]);

  menuGroup.forEach(e => {
    e.groupName = groupNameMap[e.id];
    e.itemList.sort(SORT_FN);
  });

  return menuGroup;
}

export function hasEntry(arr = []) {
  return arr.some(e => e.type === MENU_TYPE.ENTRY);
}

export function hasForHere(arr = []) {
  return arr.some(e => e.menuCode === FORHERE);
}
