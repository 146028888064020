import { throttle } from '@/util';
export default {
  name: 'PageContainer',
  props: {
    scrollTop: {
      default: () => 0
    }
  },
  data() {
    return {
      isKeepAlive: false,
      scrollTop: 0
    };
  },
  beforeMount() {
    this.handleOnScroll = throttle(this.handleOnScroll, 1000 / 30);
  },
  activated() {
    this.isKeepAlive = true;
    this.scrollToTop(this.scrollTop);
  },
  methods: {
    handleOnScroll() {
      const top = this.$refs.$pageContainer.scrollTop;
      this.$emit('update:scrollTop', top);
      if (this.isKeepAlive) this.scrollTop = top;
    },
    scrollToTop(top) {
      this.$refs.$pageContainer.scroll(0, top);
    },
    scrollToBottom() {
      const $pageContainer = this.$refs.$pageContainer;
      const scrollHeight = $pageContainer.scrollHeight;
      const clientHeight = $pageContainer.clientHeight;
      const top = scrollHeight - clientHeight;
      this.scrollToTop(top);
    }
  }
};