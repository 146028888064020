import moment from 'moment';

export const DAY_MS = 24 * 60 * 60 * 1000;
export const formatYMD = date => moment(date).format('YYYY-MM-DD');


export const get30Day = () => {
  const date = new Date();
  const start = formatYMD(new Date(date.getTime() - DAY_MS * 29));
  const end = formatYMD(date);
  return [start, end];
};
export const get7Day = () => {
  const date = new Date();
  const start = formatYMD(new Date(date.getTime() - DAY_MS * 6));
  const end = formatYMD(date);
  return [start, end];
};
export const getYesterday = () => {
  const day = formatYMD(new Date(Date.now() - DAY_MS));
  return [day, day];
};
export const getToday = () => {
  const day = formatYMD(new Date());
  return [day, day];
};

export const defaultOpts = () => {
  const NOW = new Date().getTime();
  return {
    minDate: moment(NOW - (5 * 365 * DAY_MS)).toDate(),
    maxDate: moment(NOW + (365 * DAY_MS)).toDate(),
  };
};

export const isValid = (startDate, endDate) => {
  if (startDate && endDate)
    return moment(startDate + ' 00:00:00').valueOf() < moment(endDate + ' 23:59:59').valueOf();
  else
    return true;
};
