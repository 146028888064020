import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    "theme-vars": $setup.themeVars,
    class: _normalizeClass([$setup.themeClass])
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
      themeClass: $setup.themeClass
    })]),
    _: 3
  }, 8, ["theme-vars", "class"]);
}