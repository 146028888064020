import {
  getStoreInfo
} from '@/api/store';
import { usePermissionStore } from '@/stores/permission';

const USER_TOKEN = 'pany_token';
const USER_ID = 'pany_userId';
const USER_PHONENUMBER = 'pany_userPhonenumber';
const USER_STORE_INFO = 'pany_userStoreInfo';
const USER_SEARCH_HISTORY_NAME = 'pany_userSearchHistory';
const USER_INVITATION_CODE = 'pany_userInvitationCode';
const GOODS_PAGE_CLASSIFY = 'pany_goodsPageClassify';
const DESK = 'pany_desk';
const VISITOR_SCAN_SHOP_CODE = 'pany_visitorScanShopCode'; // 记录游客状态下的用户扫店铺码的code
const LOCAL_GOODS_MODIFY_DATA = 'pany_localGoodsModify';
const MY_ORDER_TAB_PAGE_REFRESH_STATE = 'pany_myOrderTabPageRefreshState';
const PREJECTINFO = 'projectInfo';
const IFLOGIN = 'userIfLogin';
const PROJECT_CODE = 'pany_login_projectCode';
const GOODS_COMMENT_DATA = 'pany_goodsCommentData';
const PROJECT_ID  = 'pany_projectId'

export const SAVE_TOKEN = token => {
  window.sessionStorage.setItem(USER_TOKEN, token);
};

export const GET_TOKEN = () => {
  return window.sessionStorage.getItem(USER_TOKEN) || '';
};

export const SAVE_USER_ID = userId => {
  window.sessionStorage.setItem(USER_ID, userId);
};

export const GET_USER_ID = () => {
  return window.sessionStorage.getItem(USER_ID);
};

export const SAVE_PROJECT_ID = projectId => {
  window.sessionStorage.setItem(PROJECT_ID, projectId);
};

export const GET_PROJECT_ID = () => {
  return window.sessionStorage.getItem(PROJECT_ID) || '';
};

export const SAVE_PROJECT_CODE = projectCode => {
  window.sessionStorage.setItem(PROJECT_CODE, projectCode);
};

export const GET_PROJECT_CODE = () => {
  return window.sessionStorage.getItem(PROJECT_CODE);
};

export const SAVE_PHONENUMBER = phonenumber => {
  window.sessionStorage.setItem(USER_PHONENUMBER, phonenumber);
};

export const GET_PHONENUMBER = () => {
  return window.sessionStorage.getItem(USER_PHONENUMBER);
};

export const SAVE_STORE_INFO = () => {
  return usePermissionStore().initStoreInfo();
};

export const GET_STORE_INFO = () => {
  return JSON.parse(window.sessionStorage.getItem(USER_STORE_INFO)) || {};
};

export const SAVE_USER_SEARCH_HISTORY = searchArr => {
  searchArr = Array.isArray(searchArr) ? searchArr : [];
  window.sessionStorage.setItem(USER_SEARCH_HISTORY_NAME, JSON.stringify(searchArr));
};

export const GET_USER_SEARCH_HISTORY = () => {
  let searchArr = JSON.parse(window.sessionStorage.getItem(USER_SEARCH_HISTORY_NAME) || null);
  return Array.isArray(searchArr) ? searchArr : [];
};

export const SAVE_USER_INVITATION_CODE = invitationCode => {
  window.sessionStorage.setItem(USER_INVITATION_CODE, invitationCode);
};

export const GET_USER_INVITATION_CODE = () => {
  return window.sessionStorage.getItem(USER_INVITATION_CODE) || '';
};

export const SAVE_DESK = desk => {
  window.sessionStorage.setItem(DESK, desk);
};

export const GET_DESK = () => {
  return window.sessionStorage.getItem(DESK) || '';
};

export const SAVE_GOODS_PAGE_CLASSIFY = classifyId => {
  window.sessionStorage.setItem(GOODS_PAGE_CLASSIFY, classifyId);
};

export const GET_GOODS_PAGE_CLASSIFY = () => {
  const classify = window.sessionStorage.getItem(GOODS_PAGE_CLASSIFY) || '';
  window.sessionStorage.setItem(GOODS_PAGE_CLASSIFY, '');
  return classify;
};

export const SAVE_VISITOR_SCAN_SHOP_CODE = code => {
  window.sessionStorage.setItem(VISITOR_SCAN_SHOP_CODE, code);
};

export const GET_VISITOR_SCAN_SHOP_CODE = () => {
  return window.sessionStorage.getItem(VISITOR_SCAN_SHOP_CODE) || '';
};

export const SET_LOCAL_GOODS_MODIFY_DATA = data => {
  data = data || {};
  window.sessionStorage.setItem(LOCAL_GOODS_MODIFY_DATA, JSON.stringify(data));
};

export const GET_LOCAL_GOODS_MODIFY_DATA = () => {
  return JSON.parse(window.sessionStorage.getItem(LOCAL_GOODS_MODIFY_DATA)) || {};
};

export const REFRESH_MY_ORDER_TAB_PAGE = () => {
  window.sessionStorage.setItem(MY_ORDER_TAB_PAGE_REFRESH_STATE, 1);
};

export const GET_MY_ORDER_TAB_PAGE_REFRESH_STATE = () => {
  const state = window.sessionStorage.getItem(MY_ORDER_TAB_PAGE_REFRESH_STATE) || '';
  window.sessionStorage.setItem(MY_ORDER_TAB_PAGE_REFRESH_STATE, '');
  return state;
};


export const SAVE_PREJECTINFO = projectInfo => {
  window.sessionStorage.setItem(PREJECTINFO, projectInfo);
};

export const GET_PREJECTINFO = () => {
  return window.sessionStorage.getItem(PREJECTINFO) || '';
};

export const SET_GOODS_COMMENT_DATA = data => {
  data = data || {};
  window.sessionStorage.setItem(GOODS_COMMENT_DATA, JSON.stringify(data));
};

export const GET_GOODS_COMMENT_DATA = () => {
  return JSON.parse(window.sessionStorage.getItem(GOODS_COMMENT_DATA)) || {};
};
